<template>
    <section class="w-100 h-100 ml-5 mr-5">
        <div class="row mx-0 mt-4">
            <div class="col-auto">
                <p class="text-general">
                    Seleccionar las clasificaciones que puede llegar a tener el usuario para obtener el descuento de lista de acuerdo a su desempeño
                </p>
            </div>
            <div class="btn-general border br-5 px-3 ml-auto f-15 mr-5 cr-pointer" @click="confirmUpdateClasificacion">
                Actualizar clasificación
            </div>
        </div>
        <div class="row mx-0 mt-4">
            <div v-if="lista.length > 0" class="col-12">
                <el-collapse v-for="(data, idx) in lista" :key="idx" accordion @change="despliegue($event, data.id, idx)">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <el-checkbox v-model="data.estado" :true-label="1" :false-label="0" class="check-green text-general" @change="actualizarParametro('estado', data.id)" />
                            <img :src="data.imagen" style="width: 45px; height:45px" class="ml-3" />
                            <span class="text-general f-18 ml-3">{{ data.nombre }}</span>
                        </template>
                        <div class="row mx-0 mt-3">
                            <div class="col-4">
                                <p class="text-general f-18">Parámetros</p>
                            </div>
                        </div>
                        <!-- Inicio lista de parámetros -->
                        <template v-if="data.collapse">
                            <div class="row mx-0 mt-4">
                                <div class="col-4 d-middle">
                                    <p class="text-general f-16">Compras x Días</p>
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Ventas</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).compras_valor" placeholder="Porcentaje" size="small" :max="4294967295" @blur="actualizarParametro('compras_valor', data.id)" />
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Días</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).compras_dias" placeholder="Días" size="small" :max="65535" @blur="actualizarParametro('compras_dias', data.id)" />
                                </div>
                            </div>
                            <div class="row mx-0 mt-4">
                                <div class="col-4 d-middle">
                                    <p class="text-general f-16">Pedidos x Días</p>
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Pedidos</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).pedidos_valor" placeholder="Pedidos" size="small" :max="65535" @blur="actualizarParametro('pedidos_valor', data.id)" />
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Días</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).pedidos_dias" placeholder="Días" size="small" :max="65535" @blur="actualizarParametro('pedidos_dias', data.id)" />
                                </div>
                            </div>
                            <div class="row mx-0 mt-4">
                                <div class="col-4 d-middle">
                                    <p class="text-general f-16">Calificación promedio x Días</p>
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Calificación</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).calificacion_valor" placeholder="Calificación" size="small" :maxlength="3" @blur="actualizarParametro('calificacion_valor', data.id)" />
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Días</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).calificacion_dias" placeholder="Días" size="small" :max="65535" @blur="actualizarParametro('calificacion_dias', data.id)" />
                                </div>
                            </div>
                            <div class="row mx-0 mt-4">
                                <div class="col-4 d-middle">
                                    <p class="text-general f-16">Cumplimiento de pago x Días</p>
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Porcentaje</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).pago_valor" placeholder="Porcentaje" size="small" :max="255" @blur="actualizarParametro('pago_valor', data.id)" />
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Días</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).pago_dias" placeholder="Días" size="small" :max="65535" @blur="actualizarParametro('pago_dias', data.id)" />
                                </div>
                            </div>
                            <div class="row mx-0 mt-4">
                                <div class="col-4 d-middle">
                                    <p class="text-general f-16">Clientes nuevos x Días</p>
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Clientes</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).clientes_valor" placeholder="Clientes" size="small" :max="65535" @blur="actualizarParametro('clientes_valor', data.id)" />
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Días</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).clientes_dias" placeholder="Días" size="small" :max="65535" @blur="actualizarParametro('clientes_dias', data.id)" />
                                </div>
                            </div>
                            <div class="row mx-0 mt-4">
                                <div class="col-4 d-middle">
                                    <p class="text-general f-16">Antigüedad en días</p>
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Mayor a</p>
                                    <el-input v-model="parametros.find(item => item.id_lista === data.id).antiguedad" placeholder="Clientes" size="small" :max="65535" @blur="actualizarParametro('antiguedad', data.id)" />
                                </div>
                            </div>
                            <!-- Fin lista de parámetros -->
                            <div class="row mx-0 mt-5">
                                <div class="col-auto">
                                    <p class="text-general f-18">Porcentajes de descuento según el valor del ticket</p>
                                </div>
                            </div>
                            <!-- Inicio Formulario -->
                            <div class="row mx-0 mt-4">
                                <div class="col-auto">
                                    <p class="text-general mx-2">Desde</p>
                                    <money v-model="data.desde" class="input-money w-100" v-bind="money" />
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Hasta</p>
                                    <money v-model="data.hasta" class="input-money w-100" v-bind="money" />
                                </div>
                                <div class="col-auto">
                                    <p class="text-general mx-2">Descuento</p>
                                    <money v-model="data.descuento" class="input-money w-100" v-bind="moneyDecimal" />
                                </div>
                                <div class="col-auto d-middle mt-4">
                                    <div class="bg-general cr-pointer d-middle-center br-10" style="width:35px;height:35px;" @click="guardarDescuento(data.id, data.desde, data.hasta, data.descuento, idx)">
                                        <el-tooltip class="item" effect="light" content="Guardar" placement="bottom">
                                            <i class="icon-plus f-20 text-white" />
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div v-for="(item, idx2) in parametros.find(item2 => parseInt(item2.id_lista) === parseInt(data.id)).descuentos" :key="idx2" class="row mx-0 mt-2">
                                <div class="col-auto">
                                    <money v-model="item.desde" class="input-money w-100" :class="!item.editar ? 'class-disabled' : ''" size="small" v-bind="money" :disabled="!item.editar" />
                                </div>
                                <div class="col-auto">
                                    <money v-model="item.hasta" class="input-money w-100" :class="!item.editar ? 'class-disabled' : ''" size="small" v-bind="money" :disabled="!item.editar" />
                                </div>
                                <div class="col-auto">
                                    <money v-model="item.descuento" class="input-money w-100" :class="!item.editar ? 'class-disabled' : ''" size="small" v-bind="moneyDecimal" :disabled="!item.editar" />
                                </div>
                                <div class="col-auto">
                                    <el-tooltip v-if="!item.editar" class="item" effect="light" content="Editar" placement="bottom">
                                        <div class="btn-general-2 border d-middle-center cr-pointer br-10" style="width:35px;height:35px;" @click="item.editar = !item.editar">
                                            <i class="icon-pencil-outline f-20" />
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                                        <div class="bg-general-2 border cr-pointer d-middle-center br-10" style="width:35px;height:35px;" @click="actualizarDescuento(item, item.id_lista)">
                                            <i class="icon-ok-circled-outline text-green f-20" />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="col-auto px-0">
                                    <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                                        <div class="btn-general-2 border d-middle-center cr-pointer br-10" style="width:35px;height:35px;" @click="confirmarEliminar(item.id, item.id_lista)">
                                            <i class="icon-trash-empty f-20" />
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                        <cargando v-if="!data.collapse" />
                        <!-- Fin Formulario -->
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div v-else class="col-12">
                <p class="f-14 text-general">No hay información</p>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="confirmarElimnar" titulo="Eliminar porcentaje de descuento" no-aceptar adicional="Eliminar" @adicional="eliminarDescuento">
            <div class="row mx-0 align-items-center">
                <div class="col text-center">
                    <p class="text-general">
                        ¿Está seguro de realizar esta acción?
                    </p>
                </div>
            </div>
        </modal>
        <modalCambiarClasificacion ref="modalConfirmarClasificacion" />
    </section>
</template>
<script>
import Service from '~/services/configurar/admin/descuentosLista';
import {Money} from 'v-money'

export default {
    components:{
        Money,
        modalCambiarClasificacion: () => import('../partials/modalCambiarClasificacion.vue'),
    },

    props:{
        lista:{
            type: Array,
            default:() => [],
        },
        cedis: {
            type: Number,
            default: 0
        }
    },

    data(){
        return {
            parametros: [],
            tipo: 1,
            desde: 0,
            hasta: 0,
            descuento: '',
            idDescuento: null,
            idLista: null,
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            moneyDecimal: {
                decimal: '.',
                thousands: '.',
                precision: 1,
                maxlength: 4,
                masked: false   
            },
        }
    },

    watch:{
        cedis(){
            this.limpiar();
        },
        lista(){
            if(this.lista.length < 1){
                this.limpiar();
            }
        },
    },

    methods:{
        async despliegue(event, idLista, index){
            if(event){
                const existe = this.parametros.find(item => Number(item.id_lista) === Number(idLista));
                if(!existe){
                    await this.getParametros(idLista);
                    this.lista[index].collapse = true;
                    return;
                }
                setTimeout(() => {
                    this.lista[index].collapse = true;
                },100);
                return;
            }
            setTimeout(() => {
                this.lista[index].collapse = false;
            },500);
        },

        async getParametros(idLista){
            try {
                if(this.cedis === 0)return;

                const params = {
                    idCedis: this.cedis,
                    tipo: this.tipo
                };
                const {data} = await Service.getParametros(idLista, params);
                if(data?.data){
                    this.parametros.push(data.data);
                }
            } catch(e){
                this.error_catch(e);
            }
        },

        async actualizarParametro(column, idLista){
            try {
                let value = null;
                if(column != 'estado'){
                    value = this.parametros.find(item => item.id_lista === idLista);
                } else {
                    value = this.lista.find((item) => item.id === idLista);
                }
                
                if(!value){
                    return;
                }
                
                const payload = {
                    idLista: idLista,
                    idCedis: this.cedis,
                    tipo: this.tipo,
                    column: column,
                    value: value[''+column+'']
                };

                const {data} = await Service.cambioValorParametro(payload);
                if(data.exito){
                    this.notificacion('','Parámetro actualizado','success');
                }
            } catch(e){
                this.error_catch(e);
            }
        },

        async guardarDescuento(idLista, desde, hasta, descuento, indexLista){
            try {
                const payload = {
                    idCedis: this.cedis,
                    idLista: idLista,
                    tipo: this.tipo,
                    desde: parseInt(desde) != 0 ? desde : null,
                    hasta: parseInt(hasta) != 0 ? hasta : null,
                    descuento: descuento
                };
                const {data} = await Service.postDescuento(payload);
                if(data.exito){
                    this.lista[indexLista].desde = 0;
                    this.lista[indexLista].hasta = 0;
                    this.lista[indexLista].descuento = '';

                    let index = this.parametros.findIndex((item) => item.id_lista === idLista);
                    if(index !== -1){
                        this.parametros[index].descuentos.push(data.nuevo);
                        _.orderBy(this.parametros[index].descuentos, ['desde',], ['asc']);
                    }
                    this.notificacion('Mensaje', 'Se ha creado el descuento', 'success');
                }
            } catch(e){
                this.error_catch(e);
            }
        },

        async actualizarDescuento(info, idLista){
            try {
                const payload = {
                    idLista: idLista,
                    desde: parseInt(info.desde) != 0 ? info.desde : null,
                    hasta: parseInt(info.hasta) != 0 ? info.hasta : null,
                    descuento: info.descuento,
                };
                const {data} = await Service.putDescuento(info.id, payload);
                if(data.exito){
                    this.notificacion('Mensaje', 'Se ha actualizado el descuento', 'success');
                    info.editar = false;
                    return info;
                }
            } catch(e){
                this.error_catch(e);
            }
        },

        confirmarEliminar(idDescuento, idLista){
            this.idDescuento = idDescuento;
            this.idLista = idLista;
            this.$refs.confirmarElimnar.toggle();
        },

        async eliminarDescuento(){
            try {
                if(!this.idDescuento)return;

                const {data} = await Service.deleteDescuento(this.idDescuento);
                if(data.exito){
                    this.notificacion('Mensaje', 'Se ha eliminado el descuento', 'success');
                    let descuentos = this.parametros.find(item => item.id_lista === this.idLista).descuentos;
                    if(descuentos){
                        let index = descuentos.findIndex(item => item.id === this.idDescuento);
                        if(index !== -1){
                            descuentos.splice(index, 1);
                        }
                    }
                    
                    this.idDescuento = null;
                    this.idLista = null;
                }
                this.$refs.confirmarElimnar.toggle();
            } catch(e){
                this.error_catch(e);
            }
        },

        confirmUpdateClasificacion(){
            if(!this.cedis)return;

            this.$refs.modalConfirmarClasificacion.toggle('cliente', this.cedis);
        },

        limpiar(){
            this.parametros = [];
            this.desde = 0;
            this.hasta = 0;
            this.descuento = '';
            this.idDescuento = null;
            this.idLista = null;
        },
    },
}
</script>
<style scoped>
.class-disabled {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    color: #C0C4CC;
    cursor: not-allowed;
}
</style>
